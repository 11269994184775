#NavBar{    
    .active{
        background-color:  #C4A569;
        border-radius: 20px;
    }
    .inactive{
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 4px 20px #0000000D;
    }
    
}

#imageSize{
    width: 80px;
    height: 80px;
}

@media(max-width:370px){
    #imageSize{
        width: 50px;
        height: 50px;
    }

    #smallerFontSize{
        font-size: 8px;
    }
}

.discord-lecture-image{
    width: 150px;
    height: 150px;
}
