/**=====================
    78. Sidebar CSS Start
==========================**/
%sidebar-after {
  position: absolute;
  content: "";
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
%horizontal-after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}
.sidebar-mobile {
  display: block;

  @media (min-width: 576px) {
    display: none;
  } 
}
.page-wrapper {
  position: relative;
  .page-body-wrapper {
    background-color: #fff;
  }
  &.horizontal-wrapper {
    .main-header-left {
      width: 150px !important;
      .toggle-sidebar {
        display: none;
      }
    }
    .horizontal-menu {
      .sidebar-title {
        display: none !important;
      }
      &.scorlled {
        .main-nav {
          display: none;
          transition: all 0.3s ease;
        }
      }
    }
    .page-body-wrapper {
      &.horizontal-menu {
        header.main-nav {
          z-index: 3;
          height: 54px;
          line-height: inherit;
          position: fixed;
          width: 100%;
          top: 82px;
          background: $white;
          box-shadow: 0px 9px 24px rgba($semi-dark, 0.05);
          .logo-wrapper,
          .logo-icon-wrapper {
            display: none;
          }
          .main-navbar {
            text-align: left;
            position: relative;
            #mainnav {
              width: 1920px;
              position: fixed;
              left: 0;
              top: 85px;
            }
            .left-arrow,
            .right-arrow {
              position: absolute;
              top: 0px;
              padding: 15px;
              background-color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 1;
              cursor: pointer;
              &.disabled {
                display: none;
              }
            }
            .left-arrow {
              left: 0;
            }
            .right-arrow {
              right: 0;
            }
            .nav-menu {
              list-style: none;
              margin: 0;
              padding: 0;
              line-height: normal;
              direction: ltr;
              text-align: left;
              display: -webkit-box !important;
              padding: 0 5px;
              transition: all 0.5s ease;
              white-space: nowrap;
              li {
                &.dropdown {
                  &:hover {
                    a {
                      &.active {
                        svg {
                          stroke: $primary-color;
                        }
                      }
                    }
                  }
                  a {
                    &.active {
                      .according-menu {
                        i {
                          &:before {
                            content: "-";
                          }
                        }
                      }
                    }
                    .according-menu {
                      display: none;
                    }
                  }
                  ul.nav-submenu {
                    li {
                      a {
                        &.active {
                          color: $primary-color;
                          &:after {
                            background-color: $primary-color;
                          }
                        }
                      }
                    }
                  }
                }
              }
              > li {
                position: relative;
                float: left;
                display: block;
                padding-top: 10px;
                padding-bottom: 10px;
                &:first-child {
                  display: none !important;
                }
                > a {
                  display: block;
                  color: $theme-body-font-color;
                  line-height: 23px;
                  text-decoration: none;
                  text-transform: capitalize;
                  padding: 5px 15px;
                  margin: 0 3px;
                  letter-spacing: 0.04em;
                  position: relative;
                  border-radius: 5px;
                  font-weight: 500;
                  transition: all 0.3s ease;
                  &.active {
                    background: unset;
                    transition: all 0.3s ease;
                    svg,
                    span {
                      color: $theme-body-font-color;
                      transition: all 0.3s ease;
                    }
                  }
                  .badge {
                    position: absolute;
                    top: -5px;
                    margin-left: 1px;
                    right: 10px;
                    padding: 0.2em 0.7em;
                  }
                  svg {
                    width: 16px;
                    vertical-align: bottom;
                    margin-right: 15px;
                    stroke-width: 2.5px;
                  }
                }
                &:hover {
                  > a {
                    > span {
                      color: $primary-color;
                      transition: all 0.3s ease;
                    }
                  }
                  a {
                    background-color: rgba($primary-color, 0.15%);
                    transition: all 0.3s ease;
                    svg {
                      fill: rgba($primary-color, 0.1%);
                      stroke: $primary-color;
                      transition: all 0.3s ease;
                    }
                  }
                }
                .nav-submenu {
                  position: absolute;
                  left: 0;
                  z-index: 4;
                  box-shadow: 0 0 1px 0 $dark-gray;
                  padding: 15px 0px 15px 0px;
                  background: white;
                  display: block !important;
                  li {
                    border: 0;
                    float: none;
                    position: relative;
                    display: block;
                    a {
                      border: 0 !important;
                      background: transparent;
                      border-radius: 0 !important;
                      text-transform: capitalize;
                      padding: 4px 30px;
                      display: block;
                      transition: all 0.5s ease;
                      margin-bottom: 0;
                      line-height: 1.9;
                      font-size: 14px;
                      position: relative;
                      color: rgba(43, 43, 43, 0.6);
                      display: block;
                      letter-spacing: 0.06em;
                      font-weight: 500;
                      font-family: Roboto;
                      transition: all 0.3s ease;
                      &:after {
                        left: 20px;
                        @extend %horizontal-after;
                      }
                      &:hover {
                        color: $primary-color;
                        transform: translate(5px, 0px);
                        transition: all 0.3s ease;
                        &:after {
                          background-color: $primary-color;
                        }
                      }
                    }
                    .nav-sub-childmenu {
                      position: absolute;
                      width: 200px;
                      right: -200px;
                      top: 0;
                      background: #fff;
                      border: none;
                      padding: 15px 0px 15px 0px;
                      box-shadow: 0 0 1px 0 $dark-gray;
                    }
                    &:hover {
                      .nav-sub-childmenu {
                        display: block !important;
                      }
                    }
                  }
                }
                .mega-menu-container {
                  position: absolute;
                  right: -24%;
                  z-index: 4;
                  box-shadow: 0 0 1px 0 $dark-gray;
                  padding: 30px;
                  background: white;
                  width: 95%;
                  border-radius: 4px;
                  .mega-box {
                    width: 20%;
                    .link-section {
                      .submenu-title {
                        h5 {
                          text-transform: uppercase;
                          font-size: 14px;
                          margin-left: -13px;
                        }
                      }
                      .submenu-content {
                        box-shadow: none;
                        background-color: transparent;
                        position: relative;
                        display: block;
                        padding: 0;
                        li {
                          border: 0;
                          float: none;
                          position: relative;
                          display: block;
                          a {
                            border: 0 !important;
                            background: transparent;
                            color: $theme-body-font-color;
                            border-radius: 0 !important;
                            text-transform: capitalize;
                            padding: 3px 0;
                            font-size: 13px;
                            display: block;
                            letter-spacing: 0.07em;
                            line-height: 1.9;
                            position: relative;
                            transition: all 0.3s ease;
                            &:hover {
                              color: $primary-color;
                              transform: translate(5px, 0px);
                              transition: all 0.3s ease;
                              &:after {
                                color: $primary-color;
                                transition: all 0.3s ease;
                              }
                            }
                            &:after {
                              position: absolute;
                              content: "";
                              left: -12px;
                              top: 50%;
                              width: 5px;
                              height: 1px;
                              background-color: $light-semi-font;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:hover {
                  > a {
                    &:after {
                      left: 0;
                      right: 0;
                      opacity: 1;
                    }
                  }
                }
                &.mega-menu {
                  position: unset;
                }
              }
            }
          }
          &.white-active {
            .main-navbar {
              .nav-menu {
                > li {
                  .nav-link {
                    &.active {
                      background-color: white;
                      color: $secondary-color;
                    }
                  }
                }
              }
            }
          }
          &.overlay-white {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
        .toggle-nav {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
        .mega-box {
          .link-section {
            .demo {
              ul {
                li {
                  a {
                    position: relative;
                    &:hover,
                    &:focus {
                      padding-top: 3px;
                      padding-bottom: 3px;
                    }
                  }
                }
              }
              .new {
                position: absolute;
                top: -8px;
                right: 0px;
                color: white;
                background-color: $primary-color;
                padding: 2px 7px;
                text-transform: uppercase;
                font-size: 9px;
                line-height: 1.3;
              }
              .title {
                border-bottom: 2px solid red !important;
              }
            }
          }
        }
      }
    }
    .page-main-header {
      box-shadow: none;
      border-bottom: 1px solid #e6e6e6;
      .main-header-right {
        .form-control-plaintext {
          width: auto !important;
        }
      }
    }
  }
  .sidebar-title {
    padding-top: 15px !important;
    padding-bottom: 13px !important;
    > div {
      background-color: rgba($primary-color, 0.06);
      padding: 15px 17px;
      border-radius: 10px;
    }
    h6 {
      color: $primary-color;
      margin-bottom: 5px;
      letter-spacing: 0.4px;
    }
    p {
      color: $dark-color;
      font-size: 11px;
      margin-bottom: 0;
      text-transform: capitalize;
      line-height: 1;
    }
  }
  // left sidebar css
  &.compact-wrapper {
    .page-main-header {
      margin-left: 0px;
      width: calc(100% - 0px);
      &.close_icon {
        margin-left: 100px;
        width: calc(100% - 100px);
      }
      .main-header-right {
        .logo-wrapper {
          display: none;
        }
        .toggle-sidebar {
          display: none;
        }
      }
    }
    .page-body-wrapper {
      &.sidebar-icon {
        header {
          &.main-nav {
            position: fixed;
            border-bottom: none;
            top: 0;
            z-index: 9;
            height: auto;
            line-height: inherit;
            background: $white;
            width: 0px;
            text-align: left;
            transition: 0.3s;
            box-shadow: 0 0 21px 0 rgba($semi-dark, 0.1);
            .logo-wrapper {
              .back-btn {
                display: none;
              }
            }
            .logo-wrapper,
            .logo-icon-wrapper {
              padding: 12px 30px;
              box-shadow: -9px 0 20px rgba($semi-dark, 0.1);
            }
            .logo-icon-wrapper {
              display: none;
            }
            &.close_icon {
              margin-left: 0;
              transform: translate(-180px);
              .sidebar-title,
              .badge {
                display: none !important;
              }
              .logo-wrapper {
                display: none;
              }
              .logo-icon-wrapper {
                text-align: right;
                display: block;
              }
              &:hover {
                transform: translate(0px);
                .sidebar-title,
                .badge {
                  display: block !important;
                }
                .logo-wrapper {
                  display: block;
                }
                .logo-icon-wrapper {
                  display: none;
                }
                .main-navbar {
                  .nav-menu {
                    > li {
                      a {
                        svg {
                          float: none;
                          margin-right: 13px;
                        }
                      }
                    }
                    .dropdown {
                      .menu-title {
                        &.active {
                          ~ .menu-content {
                            display: block !important;
                          }
                        }
                      }
                    }
                  }
                }
                .according-menu {
                  display: block;
                }
              }
              .mobile-sidebar {
                transform: translate(183px);
              }
              ~ .page-body,
              ~ footer {
                margin-left: 100px;
                transition: 0.5s;
              }
              ~ .footer-fix {
                width: calc(100% - 100px);
              }
              .main-navbar {
                .nav-menu {
                  height: calc(100vh - 118px);
                  > li {
                    .nav-link {
                      &::before {
                        right: 20px;
                        left: unset;
                      }
                    }
                    a {
                      svg {
                        float: right;
                        margin-right: 0;
                      }
                    }
                  }
                  .dropdown {
                    .menu-title {
                      &.active {
                        ~ .menu-content {
                          display: none !important;
                        }
                      }
                    }
                  }
                }
              }
              .according-menu {
                display: none;
              }
            }
            .main-navbar {
              .left-arrow,
              .right-arrow {
                display: none;
              }
              .nav-menu {
                border-radius: 0;
                height: $sidebar-height;
                left: -300px;
                z-index: 99;
                transition: color 1s ease;
                overflow: auto;
                color: rgba(0, 0, 0, 0);
                margin-top: 20px;
                margin-bottom: 30px;
                &.custom-scrollbar {
                  &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px $transparent-color;
                  }
                  &::-webkit-scrollbar-thumb,
                  &::-webkit-scrollbar {
                    width: 6px;
                    border-radius: 13px;
                  }
                  &::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 0 10px;
                    background-color: $white;
                  }
                }
                &:hover {
                  &.custom-scrollbar {
                    color: rgba(68, 102, 242, 0.1);
                  }
                }
                .dropdown {
                  ul.nav-submenu {
                    li {
                      a {
                        &.active {
                          color: $primary-color;
                          &:after {
                            border-color: $primary-color;
                          }
                        }
                        .according-menu {
                          top: 7px;
                        }
                      }
                    }
                  }
                }
                li {
                  a {
                    font-weight: 500;
                    span {
                      letter-spacing: 0.7px;
                      font-family: Roboto;
                      text-transform: capitalize;
                    }
                    &.link-nav {
                      .according-menu {
                        display: none;
                      }
                    }
                  }
                  &:last-child {
                    border-bottom: none;
                  }
                }
                &.opennav {
                  left: 0;
                }
                .back-btn {
                  display: block;
                  width: 100%;
                  padding: 0;
                  .mobile-back {
                    padding: 20px;
                    color: $theme-font-color;
                    font-weight: 700;
                    text-transform: uppercase;
                    border-bottom: 1px solid #efefef;
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  }
                }
                > li {
                  display: block;
                  float: none;
                  width: 100%;
                  padding: 0 20px;
                  &.dropdown {
                    .badge {
                      position: absolute;
                      right: 30px;
                      top: 14.5px;
                      padding: 0.44em 0.7em 0.32em;
                    }
                    &:hover {
                      > a {
                        &:hover {
                          background-color: rgba($primary-color, 0.12%);
                          transition: all 0.3s ease;
                        }
                      }
                    }
                  }
                  &:hover {
                    .nav-link:not(.active) {
                      &:hover {
                        svg {
                          fill: rgba($primary-color, 0.1%);
                          stroke: $primary-color;
                          transition: all 0.3s ease;
                        }
                        span {
                          color: $primary-color;
                          transition: all 0.3s ease;
                        }
                        .according-menu {
                          i {
                            color: $primary-color;
                            transition: all 0.3s ease;
                          }
                        }
                      }
                    }
                  }
                  a {
                    padding: 12px 15px;
                    position: relative;
                    color: #222222;
                    transition: all 0.3s ease;
                    svg {
                      width: 18px;
                      margin-right: 10px;
                      vertical-align: bottom;
                      float: none;
                      stroke-width: 2.3px;
                      color: $dark-color;
                      transition: all 0.3s ease;
                    }
                    .sub-arrow {
                      right: 20px;
                      position: absolute;
                      top: 10px;
                      i {
                        display: none;
                      }
                      &:before {
                        display: none;
                      }
                    }
                  }
                  &:first-child {
                    display: none !important;
                  }
                  .nav-submenu {
                    width: 100%;
                    padding: 0;
                    position: relative !important;
                    > li {
                      a {
                        padding-left: 40px !important;
                        font-weight: 400;
                      }
                    }
                    .submenu-title {
                      .according-menu {
                        top: 8px;
                      }
                    }
                    &.opensubmenu {
                      display: block;
                    }
                    li {
                      &:hover {
                        > a {
                          color: $primary-color;
                          transition: all 0.3s ease;
                          &:after {
                            border-top: 2px solid $primary-color;
                            transition: all 0.3s ease;
                          }
                        }
                      }

                      .nav-sub-childmenu {
                        display: none;
                        position: relative !important;
                        right: 0;
                        width: 100%;
                        padding: 0;
                        &.opensubchild {
                          display: block;
                        }
                        li {
                          a {
                            padding: 6px 45px;
                            padding-left: 56px !important;
                            &:after {
                              display: none;
                            }
                          }
                        }
                      }
                      a {
                        padding: 7px 25px;
                        font-size: 14px;
                        color: rgba($theme-body-font-color, 0.6);
                        display: block;
                        position: relative;
                        letter-spacing: 0.06em;
                        font-weight: 500;
                        font-family: Roboto;
                        &:after {
                          left: 20px;
                          @extend %sidebar-after;
                        }
                        &:hover {
                          margin-left: 0;
                        }
                      }
                    }
                  }
                  &.mega-menu {
                    .menu-title {
                      &.active {
                        ~ .mega-menu-container {
                          display: block !important;
                        }
                      }
                    }
                  }
                  .mega-menu-container {
                    padding: 0;
                    position: relative;
                    &.opensubmenu {
                      display: block;
                    }
                    .mega-box {
                      width: 100%;
                      padding: 0;
                      .link-section {
                        &:hover {
                          h5 {
                            color: $primary-color !important;
                            transition: all 0.3s ease;
                            &:after {
                              border-top: 2px solid $primary-color;
                              transition: all 0.3s ease;
                            }
                          }
                        }
                        li {
                          &:hover {
                            border-bottom: 1px solid #f7f7f7;
                          }
                        }
                        .submenu-title {
                          position: relative;
                          h5 {
                            margin-bottom: 0;
                            line-height: 1.9;
                            padding: 6px 25px 6px 45px;
                            font-size: 14px;
                            position: relative;
                            color: rgba(43, 43, 43, 0.6);
                            display: block;
                            letter-spacing: 0.06em;
                            font-weight: 500;
                            font-family: Roboto;
                            cursor: pointer;
                            &:after {
                              left: 25px;
                              @extend %sidebar-after;
                            }
                          }
                          .according-menu {
                            top: 8px;
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                margin-bottom: 0;
                                line-height: 1.9;
                                padding: 6px 25px 6px 45px;
                                font-size: 14px;
                                position: relative;
                                color: rgba(43, 43, 43, 0.6);
                                display: block;
                                letter-spacing: 0.06em;
                                font-weight: 500;
                                font-family: Roboto;
                                &:after {
                                  display: none;
                                }
                                &.active {
                                  color: $primary-color;
                                }
                              }
                            }
                          }
                          ul {
                            li {
                              a {
                                line-height: 1.9;
                                &:hover {
                                  margin-left: 0;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .nav-link {
                    border-radius: 10px;
                    transition: all 0.5s ease;
                    &.active {
                      background-image: linear-gradient(90deg, $primary-color 0%, $primary-color-light 100%);
                      transition: all 0.5s ease;
                      position: relative;
                      box-shadow: 0px 0px 12px 0px rgba($primary-color, 0.35);
                      margin-bottom: 10px;
                      .badge {
                        color: $primary-color !important;
                        background-color: $white !important;
                      }
                      .according-menu {
                        i {
                          color: $white;
                        }
                      }
                      svg {
                        color: $white;
                        transition: all 0.3s ease;
                      }
                      span {
                        color: $white;
                        transition: all 0.3s ease;
                      }
                    }
                  }
                }
              }
            }
          }
          .menu {
            nav {
              margin-left: auto;
              margin-right: 30px;
            }
          }
        }
        .mega-box {
          .link-section {
            .demo {
              ul {
                li {
                  a {
                    span {
                      img {
                        top: 30px;
                        right: -60px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .mega-box {
          &.col {
            flex-basis: unset;
          }
        }
        .according-menu {
          position: absolute;
          right: 15px;
          top: 13px;
          display: block;
          i {
            vertical-align: middle;
            color: rgba($black, 0.8);
            font-weight: 600;
          }
        }
        .main-menu {
          &.border-section {
            border: none;
          }
          .menu-left {
            .main-menu-right {
              .toggle-nav {
                position: absolute;
                z-index: 2;
                right: 145px;
                top: 6px;
                .sidebar-name {
                  font-size: 18px;
                  padding-left: 5px;
                  text-transform: uppercase;
                }
              }
            }
          }
        }
        .page-body {
          padding: 0 25px;
          min-height: calc(93vh - 120px);
          margin-top: 150px;
          margin-left: 0px;
        }
      }
    }
  }
}
.rtl {
  .link-section {
    float: right;
  }
  .mega-box {
    .link-section {
      .demo {
        ul {
          li {
            a {
              span {
                img {
                  right: unset;
                  left: -120px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Responsive menu css start //
@media (max-width: 1780px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  > li {
                    .mega-menu-container {
                      right: -18%;
                      width: 91%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  > li {
                    .mega-menu-container {
                      right: -27%;
                      width: 74%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .responsive-btn {
    display: none;
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            .main-navbar {
              .nav-menu {
                > li {
                  .nav-submenu {
                    width: 200px;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 5px;
                    transition: all 0.5s ease;
                    a {
                      .sub-arrow {
                        position: absolute;
                        right: 20px;
                        top: 5px;
                        color: rgba(43, 43, 43, 0.6);
                        font-weight: 100;
                        transition: all 0.5s ease;
                        font-size: 9px;
                        top: 12px;
                      }
                    }
                    li {
                      .nav-sub-childmenu {
                        display: none;
                        border-radius: 4px;
                      }
                      &:hover {
                        .nav-sub-childmenu {
                          display: block;
                        }
                      }
                      a {
                        &:hover {
                          .sub-arrow {
                            right: 16px;
                            transition: all 0.5s ease;
                          }
                        }
                      }
                    }
                  }
                  .mega-menu-container {
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.5s ease;
                  }
                  &:hover {
                    .nav-submenu {
                      opacity: 1;
                      visibility: visible;
                      margin-top: 8px;
                      transition: all 0.5s ease;
                    }
                    .mega-menu-container {
                      opacity: 1;
                      visibility: visible;
                      margin-top: 20px;
                      transition: all 0.5s ease;
                    }
                  }
                }
              }
            }
          }
        }
        .toggle-nav {
          display: none;
        }
        .mobile-back {
          display: none !important;
        }
      }
    }
  }

  .rtl {
    .mega {
      .full-mega-menu {
        float: left !important;
        left: 50% !important;
        right: auto !important;
        &.center-mega-menu {
          margin-right: 0 !important;
          margin-left: 0 !important;
          left: unset !important;
          right: 50% !important;
        }
      }
    }
  }
}
@media (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  > li {
                    .mega-menu-container {
                      right: -54%;
                      width: 75%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .rtl {
    .pixelstrap {
      .mega {
        .full-mega-menu {
          margin-left: 0 !important;
          margin-right: 162px !important;
        }
      }
    }
  }
  header {
    .main-navbar {
      .nav-menu {
        > li {
          margin-right: 8px;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  > li {
                    .mega-menu-container {
                      right: -49%;
                      width: 70%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .left-menu-header .mega-menu-container .submenu-title {
    margin: 20px 20px 0 20px;
  }
  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 14px 30px 6px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;
      h5 {
        color: $primary-color;
      }
      svg {
        stroke: $primary-color !important;
      }
    }
  }
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              .main-navbar {
                .nav-menu {
                  > li {
                    .mega-menu-container {
                      right: -35%;
                      width: 56%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  header {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  header {
    .menu {
      nav {
        margin-right: 20px;
      }
    }
  }
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}
/**=====================
    78. Sidebar CSS Ends
==========================**/
