#displayGraph {
    padding: 0 25px;
}

#follow-button {
    height: 55px;
    width: 100%;
    font-size: 18px;
    color: black;
    border-radius: 20px;

}

#pamm-text{
    font-size:16px;
    margin-bottom: 1rem;

}

#pamm-sub-name{
    font-size: 20px;
    text-overflow: ellipsis;
}

#pamm-record-text{
    font-size:18px;
    margin:20px;
}

@media (max-width: "768px") {
    .flex-direction-mobile {
        flex-direction: column;
    }

    .pamm-stats-mobile {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .pamm-stats-mobile div p {
        font-size: 12px !important;
    }

    .pamm-stats-mobile div p {
        font-size: 12px !important;
    }

    .pamm-stats-mobile div p:first-child {
        font-weight: 400;
    }

    #col-line-graph {
        padding-left: 0;
        padding-right: 0;
        margin-top: 20px;
    }

    #displayGraph {
        padding: 0;
    }
}

@media(max-width: "576px") {

    #follow-button {
        height: 40px;
        font-size: 14px;
        color: black;
        border-radius: 20px;
        margin-bottom: 1rem;
    }

    #pamm-text{
        font-size:14px;
    }
    #pamm-sub-name{
        font-size: 16px;
    }
    #pamm-record-text{
        font-size:14px;
        margin:20px;
    }
}