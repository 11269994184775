#LanguageRadio {
    width: 20px;
    height: 15px;
    accent-color: #EFB935;
}

/* .language-container{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align:center;
} */
.language-box{
    justify-content: space-between;
    width: 100%;
    display:flex;
    flex-direction: column;
}

.language-switch{
    position: relative;
    display: inline-block;
    width: 85px;
    height: 40px;
    margin: 0;
}

.language-slider{
    position:absolute;
    cursor:pointer;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgb(196, 165, 105);
    transition: .3s;
    border-radius: 34px;

}
.language-slider-inactive{
    position:absolute;
    cursor:pointer;
    top: 0;
    left:0;
    right:0;
    bottom:0;
    background-color:rgb(196, 165, 105);
    transition: .3s;
    border-radius: 34px;
}


.language-switch input {display:none}

.language-slider:before{
    position:absolute;
    content:"";
    height: 30px;
    width: 30px;
    right: 5px;
    bottom: 5px;
    background-color: white;
    border-radius: 50px;

}

.language-slider-inactive:before{
    position:absolute;
    content:"";
    height: 30px;
    width: 30px;
    left: 5px;
    bottom: 5px;
    background-color: white;
    border-radius: 50px;
}


/* input:checked + .language-slider{
    background-color: rgb(196, 165, 105);
}

input:checked + .language-slider:before{
    transform: translateX(45px);
} */