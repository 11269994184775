@media only screen and (max-width: 480px) {
    .mobile-function-bar {
        margin: 0px 0px 100px 0px;
    }
}

@media only screen and (max-width: 1000px) {
    .mobile-monitoring {
        margin: 0px 0px 100px 0px;
    }
}