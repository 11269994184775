.required {
  text-align: center;
  margin-left:0px;
  li {
    display:inline-block;
    transition:all 0.3s ease-in-out;
    &:hover {
      .submenu {
        height: 5px;
        text-align: left;
      }
    }
    .submenu {
      position:absolute;
      display: flex;
      top:15px;
      text-align: center;
      width: 0;
      height: 0;
      line-height: 40px;
      box-sizing:border-box;
      a {
        margin-top: 20px;
        text-align:center;
        font-size: 10px;
        background-color:  #C4A569 !important;
        color: black !important;
        margin-left: 12px;
        font-weight: 500 !important;
        border-radius: 20px;
        width:150px;
        &:hover {
          transition: all 0.2s ease-out;
          transform: scale(1.1, 1.1);
          margin-left:20px;
          
        }

      }
    }
    a {
      color: #999;
      display: block;
      padding: 0 7px 0 7px;
      margin: 0 0 10px;
      text-decoration: none;
      position: relative;

    }
  }
}

//mobile 
.required-mobile {
  margin-left:0px;
  li {
    display:inline-block;
    margin-left:10px;
    .submenu {
      position:absolute;
      display: flex;
      flex-direction: row;
      margin-left:5%;
      margin-top:15px;
      top:0px;
      text-align: center;
      width: 0;
      height: 0;
      line-height: 40px;
      box-sizing:border-box;
      a {
        margin-top: 20px;
        text-align:center;
        font-size: 10px;
        background-color:  #C4A569 !important;
        color: black !important;
        margin-left: 0px;
        font-weight: 500 !important;
        border-radius: 20px;
        width:150px;
        

      }
    }
    a {
      color: #999;
      display: block;
      padding: 0 7px 0 7px;
      margin: 0 0 10px;
      text-decoration: none;
      position: relative;
      
    }
  }
}

.seSize{
  display:block;
  margin: auto;
  height: 100px;
  width: 100px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
