$light-gray:        #f8f9fa;
$menu-bg:           #fff;
$menu-hover:        darken($menu-bg, 9%);

.menu--main {
  display: block;
  position: absolute;
  bottom: 0;

  li {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 15px 20px;
    background-color: $menu-bg;
    margin-right: -4px;  // get rid of spacing between list items
    //transition: all 0.2s;
    &:hover {
      background-color: $menu-hover;
      border-radius:0px;
    }
    &:hover .sub-menu {      
      max-height: 200px;
      visibility: visible;
      top: 100%;  // align to top of parent element
      transition: all 0.4s linear;
      border-radius: 20px;
      background-color: $menu-hover;

    }
  }
  .sub-menu {
    display: block;
    border: 1px solid;
    border-color: white;
    visibility: hidden;
    position: absolute;
    //top: 100%;  // align to bottom of
    left: 0;
    box-shadow: none;
    max-height: 0;
    width: 215px;
    border-radius: 0px 0px 25px 25px;
    overflow: hidden;

    li {
      display: block;
    }
  }
}

.menuClicked--main {
  display: block;
  position: absolute;
  bottom: 0;

  li {
    display: none;
    position: relative;
    cursor: pointer;
    padding: 15px 20px;
    background-color: $menu-bg;
    margin-right: -4px;  // get rid of spacing between list items
    //transition: all 0.2s;
    &:hover {
      background-color: $menu-hover;
      border-radius:0px;
    }
    &:hover .sub-menu {      
      max-height: 200px;
      visibility: visible;
      top: 100%;  // align to top of parent element
      transition: all 0.4s linear;
      border-radius: 20px;
      background-color: $menu-hover;

    }
  }
  .sub-menu {
    display: block;
    border: 1px solid;
    border-color: white;
    visibility: hidden;
    position: absolute;
    //top: 100%;  // align to bottom of
    left: 0;
    box-shadow: none;
    max-height: 0;
    width: 215px;
    border-radius: 0px 0px 25px 25px;
    overflow: hidden;

    li {
      display: block;
    }
  }
}
