#transferIcon {
    color:  #C4A569;
}

#transferIcon:hover {
    color: white;
}

.mobileWalletButton{
    border-radius: 20px;
    background-color: white;
    font-size: 14px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    height:35px;
}

.dropdown-content-wallet{
        position: absolute;
        right: 0;
        background-color: #f9f9f9;
        box-shadow: 0px 7px 20px #00000014;
        border-radius: 20px;
        min-width: 150px;
        -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        border: none;
        z-index: 1;
        left: 0;
        margin-top:5px;
        top: 45px;
      
}

.upgradeButton,
.upgradeButton:after {
  width: 280px;
  height: 45px;
  line-height: 45px;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  background: linear-gradient(45deg, transparent 5%,  #ad986e 5%);
  color: black;
  box-shadow: 6px 0px 0px #84754e;
  letter-spacing: 2px;
  cursor: pointer;
  display: inline-block;
  padding: 0 18px;
  text-align: center;
}
.upgradeButton:hover{
    background: linear-gradient(45deg, transparent 5%,  #84754e 5%);
    box-shadow: 6px 0px 0px #ad986e;
    color:white;
    
}
  
@media (min-width: 768px) {
    .upgradeButton
    .upgradeButton:after {
      width: 280px;
      height: 50px;
      font-family: Noto Sans, sans-serif;
      font-weight: 600;
      line-height: 50px;
      text-align: center;
      cursor: pointer;
    }
  }

.upgradeButton-mobile{
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    font-size: 14px;
    background: linear-gradient(45deg, transparent 5%,  #ad986e 5%);
    color: black;
    box-shadow: 6px 0px 0px #84754e;
    letter-spacing: 2px;
    cursor: pointer;
    display: inline-block;
    padding: 0 18px;
    text-align: center;
}

@media(max-width:480px){
    .mobileWalletButton{
        border-radius: 20px;
        background-color: white;
        font-size: 14px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
        align-items: center;
        height:35px;
    }
}