//mobile size footer
.mobilefooter{
        margin:0px;
        padding:0px;
        position:fixed;
        bottom:0px;
        z-index: 1000;
        font-size: 8px;
        width:100%;
        height: 75px;
        // overflow:hidden;
        border-radius: 20px 20px 0px 0px;
        background-color: white;
        box-shadow: 0px 0px 7px 7px #00000014;
}
