.isActive {
    border-bottom: 4px solid #C4A569;
    color: black !important;
}
.isFeedbackActive {
    color: #C4A569;
    text-decoration: underline;
}

@media (max-width: 768px) {
    .mobile-font-size {
        font-size: 10px;
    }
}
