.vp-sidedock {
    display: none;
}

.vp-title-header {
    display: none;
}

#course-header {
    margin-left: 0px !important;
    width: 100% !important;
    padding: 22px 30px !important;
    background-color: #0f3c5a !important;
}

.correct-container {
    background-color: palegreen;
    border-radius: 15px;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 15px;
}

.incorrect-container {
    background-color: salmon;
    border-radius: 15px;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 15px;
}

@media (min-width: 992px) {
    #rowCourses,
    #colCoursesSide {
        height: 1000px;
    }
}

#frame-vimeo {
    height: 95%;
    width: 100%;
    padding: 25px 50px;
    // max-width: 1350px;
}

.vp-center {
    align-items: flex-start !important;
}

@media (max-width: 992px) {
    #frame-vimeo {
        height: 100%;
        width: 100%;
        padding: 0;
    }
    .correct-container {
        background-color: palegreen;
        border-radius: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
    }
    
    .incorrect-container {
        background-color: salmon;
        border-radius: 15px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 15px;
    }
}
