.project-list {
	.row {
		margin: 15px;
	}
	button:focus {
		outline: none !important;
	}
	.theme-form .form-group {
		margin-bottom: 15px;
	}
	.border-tab.nav-tabs {
		flex-direction: row;

		@media (min-width: 576px) {
			flex-direction: column;
			align-items: flex-start;
		}
		
		.nav-item .nav-link {
			border: 1px solid transparent;
			padding: 5px 30px 5px 10px;
			display: flex;
			align-items: center;
			cursor: pointer;
			border-radius: 19px;
			height:58px;
			font-size: 18px;
			font-weight:500;
			letter-spacing: 0;
		}
	}
	.btn {
		margin-right: 5px;
		vertical-align: -12px;
		svg {
			vertical-align: middle;
			height: 16px;
		}
	}
	ul {
		margin-bottom: 0 !important;
		border-bottom: 0;
		li {
			svg {
				height: 18px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
	}
}

.project-box {
	border: 1px solid darken($light, 2%);
	border-radius: 5px;
	padding: 30px;
	background-color: lighten($light, 2%);
	h6 {
		font-weight: 500 !important;
	}
	.badge {
		position: absolute;
		right: 30px;
		top: 15px;
	}
	.project-status {
		p {
			margin-bottom: 5px;
			font-weight: 800;
		}
	}
	.media {
		margin-bottom: 15px;
		.media-body {
			opacity: 0.5;
		}
	}
	.details {
		div {
			margin-bottom: 5px;
			span {
				font-weight: 600;
			}
		}
	}
}

.projectdetails {
	.card {
		.card-body {
			padding: 20px;
		}
		.card-footer {
			padding: 20px;
		}
		select {
			width: 90px;
			height: 28px;
			font-size: 11px;
			right: 20px;
			top: 20px;
		}
	}
}
.projectmore {
	.details {
		.col-4 {
			margin-bottom: 5px;
			&:nth-child(even) {
				opacity: 0.5;
			}
		}
	}
	h5 {
		font-size: 20px;
		font-weight: 600;
	}
	.task-list {
		position: relative;
		ul {
			li {
				margin-bottom: 5px;
			}
			& + ul {
				position: absolute;
				left: 50%;
				top: 0;
			}
		}
	}
}
.new-users {
	&.activity {
		.gradient-round {
			padding: 13px;
			font-size: 20px;
			line-height: 1.4;
			text-align: center;
			top: unset !important;
			&::after {
				display: none;
			}
		}
	}
}
