.courseScrollBar::-webkit-scrollbar {
    display: block;
    width: 16px;
    opacity: 0.5;
    border-radius: 24px;
    border: 1px solid  #C4A569;
  }
  
  .courseScrollBar::-webkit-scrollbar-button:single-button {
    display: none;
  }
  
  .courseScrollBar::-webkit-scrollbar-track {
    background-color: #00000000;
  }
  
  .courseScrollBar::-webkit-scrollbar-track-piece {
    background-color: #00000000;
  }
  
  .courseScrollBar::-webkit-scrollbar-thumb {
    background-color:  #C4A569;
    border: 1px solid #ffffff40;
    border-radius: 24px;
    width:20%;
  }
