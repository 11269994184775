.spinner-border{
    color: #C4A569;
}

#carnival-container.active {
    background-color: red;
}

#sidebar-carnival-container:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.1);
}

.wistia_swatch {
    height: 0%;
}

.Toastify__toast-container--top-center {
    width: 600px;
}

.toast-dashboard {
    width: 600px;
    background-color: #103c5a !important;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media (max-width: 548px) {
    #toastButtonDiv {
        display: none;
    }

    .toast-dashboard {
        max-width: 100%;
        width: 414px;
    }
}

.inviteFriend-m-l-100 {
    margin-left: 100px;
}

.carnival-m-r-45 {
    margin-right: 45px;
}

.referDepositMT5 {
    margin-left: 15px;
}

.introjs-tooltip {
    border: 2px solid #103c5a;
    min-width: 300px !important;
    font-size: 16px !important;
}

@media (max-width: 556px) {
    .inviteFriend-m-l-100 {
        margin-left: 25px;
    }

    .referDepositMT5 {
        margin-left: 0px;
        margin-top: 10px;
    }
}

#slider-referral {
    width: 85%;
}

@media (max-width: 556px) {
    #slider-referral {
        width: 100%;
    }
    .introjs-tooltip {
        border: 2px solid #103c5a;
        min-width: 300px !important;
        font-size: 16px !important;
    }

    .introjs-nextbutton {
        background-color: #103c5a !important;
        color: white !important;
        text-shadow: none !important;
    }
    .introjs-progressbar {
        background-color: #103c5a !important;
    }

    .introjs-bullets ul li a.active {
        background-color: #103c5a !important;
    }

    .tncModal {
        margin: 15.75rem auto;
        padding: 0px 15px;
    }

    .Toastify,
    .Toastify__toast-container--top-right {
        top: 108px;
    }

    #div-stater-banner {
        display: flex;
    }
}

@media (max-width: "768px") {
    .toast-notification {
        top: 0px;
        margin-bottom: 10px;
    }

    #depositWalletButton,
    #redeemMT5 {
        font-size: 14px;
    }

    #div-stater-banner {
        display: flex;
        flex-direction: column;
    }

    #div-stater-banner button {
        margin-bottom: 10px;
    }
}

// Event competition leaderboard
.BiAlg.rdt_TableHeadRow {
    background-color: #103c5a !important;
    color: white !important;
}
.haLioR {
    color: white !important;
}

#student-event-form {
    flex-direction: row;
}

#term-col {
    padding-right: 0px;
}

#form-col {
    padding-left: 0px;
}

#event-card-height {
    height: 600px;
}

#mobileLangugeDropdown {
    border-color:  #C4A569 !important;
    background-color:  #C4A569 !important;
}

.carousel-indicators{
    margin-bottom: -25px;
    z-index: 1;
    .active{
        width: 24px;
        height: 6px;
        border-radius: 1rem;
}
}

.carousel-indicators li {
    background-color: #4F5358;
    width: 12px;
    height: 6px;
    border:none !important;
    border-radius: 1rem;

}

#modal_asic {
    max-width: 60%;
}

#asic_ul {
    list-style-type: disc;
    padding-left: 25px;
    padding-right: 25px;
}

.page-wrapper.compact-wrapper
    .page-body-wrapper.sidebar-icon
    header.main-nav
    .main-navbar
    .nav-menu
    li:last-child
    .sidebar-title
    > div {
    background-color: white;
}

@media (max-width: "768px") {
    #event-card-height {
        height: 650px;
    }

    #student-event-form {
        flex-direction: column;
    }

    #term-col {
        padding-right: 15px;
    }

    #form-col {
        padding-left: 15px;
    }

    #modal_asic {
        max-width: 100% !important;
    }

    .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-icon header.main-nav .main-navbar .nav-menu li:last-child {
        margin-bottom: 20px;
    }

    .page-wrapper.compact-wrapper
        .page-body-wrapper.sidebar-icon
        header.main-nav
        .main-navbar
        .nav-menu
        li:last-child
        .sidebar-title {
        padding-top: 70px !important;
    }

    .page-wrapper.compact-wrapper
        .page-body-wrapper.sidebar-icon
        header.main-nav
        .main-navbar
        .nav-menu
        li:last-child
        .sidebar-title
        > div {
        background-color: white;
    }
}
